export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-tutorial-uploads-2019"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://emnz1wletl.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_Lk3K7rFUt",
    APP_CLIENT_ID: "3doikl83a25qmhk0s2i9543g9t",
    IDENTITY_POOL_ID: "us-east-1:3992da36-407b-4d72-92ba-6b4505e38542"
  }
};
